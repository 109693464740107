<template>
  <b-table responsive :items="subCondos" :fields="fields" class="mb-0" show-empty empty-text="No se tiene registros">
    <template #cell(code)="data">
      <span class="text-nowrap">
        {{ data.value ? data.value : "N/A" }}
      </span>
    </template>
   
   
    <template #cell(actions)="row">
      <div>      
		<b-button
			size="sm"                            
			variant="primary"
			:to="{name: 'edit-sub-condominiums', params:{ id: row.item.id }}"               
		>			
			<feather-icon icon="Edit2Icon" size="12" />
		</b-button>       
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapState,  mapMutations } from 'vuex'

export default {
	data() {
		return {
			fields: [
				{ key: 'code', label: 'Código' },
				{ key: 'name', label: 'Nombre' },
				{ key: 'condosname', label: 'Nombre del Condo' },
				{ key: 'percentage', label: 'Porcentaje' },
				{ key: 'actions', label: 'Acciones', class: 'text-center' },
			],
		};
	},
	computed: {
		...mapState('fivesClubCatalogs', ['subCondos']),    
	},
	methods: {    
		
		
	},
};
</script>